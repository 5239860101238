@tailwind base;
@tailwind components;
@tailwind utilities;


.titulo {
    @apply text-4xl font-bold text-center;
    
}

.sub-titulo {
    @apply text-2xl;
    
}