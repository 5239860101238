html,
body {
    margin: 0;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox
input[type=number] {
    -moz-appearance: textfield;
} */

#root {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    min-height: 100vh;
    scroll-behavior: smooth;
    background-color: white;
}


#root:nth-child(2) {
    flex-grow: 5;
    width: 100%;
}

.contenedor {
    /* width: 90%; */
    width: 100%;
    /* max-width: 1600px; */
    /* margin-inline: auto; */
    padding-top: 1rem;
    padding-inline: 1rem;
    overflow-y: auto;
}

button[data-testid="flowbite-dropdown-target"] {
    background-color: #111827;
    border: none !important;
    text-align: left;
    padding-inline: 0px;
}

button[data-testid="flowbite-dropdown-target"] span {
    padding: 0px !important;
}

div[data-testid="flowbite-navbar-collapse"] ul {
    margin-top: 0px !important;
}





#nav {
    position: sticky;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    transition: all .5s;
}


@media(max-width: 500px) {
    #nav {
        position: fixed;
        inset: 0;
    }
}

/* #nav #popup {
    display: none;
} */

/* #nav #popup:hover {
    visibility: visible !important;
    display: block !important;
    position: relative;
    width: 200px;
} */



table {
    width: 100%;
}

table th {
    text-align: center;
}

table td {
    text-align: left;
}

table th,
table td {
    padding-inline: 20px;
}

/* Tabla Syslog */
#tabla-syslog {
    width: 100%;
}

#tabla-syslog table {
    border-collapse: collapse;
    width: max-content;
}

@media (min-width: 1000px) {
    #tabla-syslog table {
        width: 100%;
    }
}

#tabla-syslog th {
    padding-inline: 20px;
    padding-block: 10px;
    border: 1px solid gray;
    background-color: #111827;
    color: white;
}

#tabla-syslog td {
    padding: 10px 20px;
    margin-top: 10px;
    border: 1px solid rgb(168, 166, 166);
}